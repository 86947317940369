import { nanoid } from "nanoid"
import Logger from "@/utils/Logger"

export async function useTypesenseApiKey() {
  const { $api } = useNuxtApp()
  const { tenantId, jobShopId } = useHeaderStore()
  const { data: jobShop } = useNuxtData("jobShopData")
  const vanity = jobShop.value?.jobShopCompanyVanity

  const requestId = nanoid()
  const contextString = `TypeSense API key for tenantId: "${tenantId}", jobShopId: "${jobShopId}" and vanity: "${vanity}"`

  let startTime
  if (import.meta.server) {
    Logger(requestId).start(`Start fetching ${contextString}`)
    startTime = Date.now()
  }

  const {
    data: typesenseApiKey,
    pending: typesenseApiKeyPending,
    error: typesenseApiKeyError,
  } = await useAsyncData(`typesenseApiKey-${jobShopId}`, () => $api.searchAndFilters.getTypesenseApiKey(tenantId, vanity))

  if (import.meta.server) {
    if (!typesenseApiKeyError.value) {
      const duration = Date.now() - startTime
      Logger(requestId).success(`Successfully fetched ${contextString}. (${duration}ms)`)
    }
    else {
      const errorMessage = pageError.value?.data?.errors[0]?.message || typesenseApiKeyError.value?.data?.description || "Unknown error occurred"
      Logger(requestId).error(`Error while fetching ${contextString}: ${errorMessage}\n\n`, pageError.value)
    }
  }

  return { typesenseApiKey, typesenseApiKeyPending, typesenseApiKeyError }
}
